import { Link } from "react-router-dom";
import Navbar from "../partials/Navbar"

const About = () => {
    return (
        <>
            <Navbar/>
            <div className="text-left" style={{background: 'rgb(248, 248, 248) none repeat scroll 0% 0% / auto padding-box border-box', boxSizing: 'border-box'}}>
    <div style={{marginBottom: '20px', display: 'flex', flexWrap: 'wrap', padding: '12px 16px', listStyle: 'outside none none', backgroundColor: 'rgb(233, 236, 239)', borderRadius: '4px', boxSizing: 'border-box'}}>
        <div style={{maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box'}}>
            <ul style={{padding: '0px', margin: '0px', marginTop: '0px', marginBottom: '0px', boxSizing: 'border-box'}}>
                <li style={{listStyle: 'outside none none', display: 'inline-block', color: 'rgb(54, 54, 54)', fontSize: '15px', boxSizing: 'border-box'}}><a href="/" style={{color: 'rgb(54, 54, 54)', transition: 'all 0.3s ease 0s', fontSize: '15px', textDecoration: 'none solid rgb(54, 54, 54)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>Trang chủ</a></li>
                <li style={{listStyle: 'outside none none', display: 'inline-block', color: 'rgb(54, 54, 54)', fontSize: '15px', boxSizing: 'border-box'}}>Giới thiệu</li>
            </ul>
        </div>
    </div> {/* end breadcrumbs */}
    <div style={{maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box'}}>
        <div style={{display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px', boxSizing: 'border-box'}}>
            <div style={{flex: '0 0 100%', maxWidth: '100%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box'}}>
                <article style={{boxShadow: 'rgba(0, 0, 0, 0.03) 0px 2px 10px 0px', borderRadius: '10px', backgroundColor: 'rgb(255, 255, 255)', padding: '20px', margin: '20px 0px', fontSize: '16px', marginBottom: '20px', display: 'block', boxSizing: 'border-box'}}>
                    <div style={{marginBottom: '25px', boxSizing: 'border-box'}}>
                        {/**/}
                    </div>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Xin chào,</span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Cảm ơn bạn đã ghé thăm Hoa Vạn Sắc.</span></p>
                    <h1 style={{fontSize: '40px', marginBottom: '8px', fontWeight: 500, lineHeight: '48px', marginTop: '0px', boxSizing: 'border-box'}}><span style={{fontSize: '14pt', fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}><strong style={{fontWeight: 700, boxSizing: 'border-box'}}>CÂU CHUYỆN CỦA HOA VẠN SẮC&nbsp;</strong></span></h1>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Thế hệ trẻ ngày nay đã quen thuộc với trò chơi điện tử hơn là những trò chơi dân gian mà ông bà, cha mẹ vốn gần gũi. Khoảng cách thế hệ, công việc bận rộn đôi khi ngăn cách cha mẹ đến gần với con hơn. Vậy làm thế nào để kết nối và vui chơi cùng con?</span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Thấu hiểu điều đó và cũng đồng thời đứng trên cương vị một người mẹ, Hoa Vạn Sắc ra đời với sứ mệnh đem trải nghiệm làm đồ thủ công thú vị và lành mạnh đến với các bạn nhỏ. Ngoài trải nghiệm mới mẻ, quay về với thủ công truyền thống, Hoa Vạn Sắc cũng ấp ủ mong muốn gắn kết tình thân, thu hẹp khoảng cách giữa cha mẹ và con cái.</span></p>
                    <h1 style={{fontSize: '40px', marginBottom: '8px', fontWeight: 500, lineHeight: '48px', marginTop: '0px', boxSizing: 'border-box'}}><span style={{fontSize: '14pt', fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}><strong style={{fontWeight: 700, boxSizing: 'border-box'}}>SẢN PHẨM CỦA HOA VẠN SẮC&nbsp;</strong></span></h1>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Hoa Vạn Sắc  cung cấp sản phẩm là các Hộp Sáng tạo – Bộ Kit Thủ Công tiện lợi và gọn nhẹ.</span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Hộp Sáng tạo đa dạng mẫu mã và kiểu dáng, minh họa những đồ dùng, vật dụng, nhân vật hoạt hình mà trẻ em yêu thích:</span></p>
                    <ul style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}>
                        <li style={{boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Bộ Kit Hello Kitty</span></li>
                        <li style={{boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Bộ Kit Túi Thỏ</span></li>
                        <li style={{boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Bộ Kit Ô Tô</span></li>
                        <li style={{boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Bộ Kit Ống Heo Tiết Kiệm</span></li>
                        <li style={{boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Bộ Kit Noel</span></li>
                        <li style={{boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Bộ Kit Tết</span></li>
                    </ul>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Mỗi Bộ Kit được chuẩn bị đầy đủ nguyên liệu kèm hướng dẫn thuận tiện nhất để tự làm.</span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Bên cạnh các sản phẩm cho bé, các bậc phụ huynh cũng có thể trải nghiệm các sản phẩm làm hoa handmade khác như tranh hoa nghệ thuật.</span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Xem các sản phẩm tại đây:&nbsp;<Link to="/products" target="_blank" rel="noopener" style={{textDecoration: 'none solid rgb(0, 123, 255)', color: 'rgb(0, 123, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>Sản phẩm Hoa Vạn Sắc </Link></span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontSize: '14pt', fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}><strong style={{fontWeight: 700, boxSizing: 'border-box'}}>DỊCH VỤ CỦA HOA VẠN SẮC&nbsp;</strong></span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Đến với các Workshop, lớp học dạy trẻ cách phân loại rác thải, tái chế vật dụng trong nhà (bìa giấy, vỏ chai nhựa, lõi giấy, …). Từ đó, các con hô biến chúng thành ống heo, con cua, … . Những đồ tái chế này sau đó có thể dùng trang trí góc học tập.</span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Các đơn vị, tổ chức mong muốn tìm hiểu về dịch vụ vui lòng liên hệ để được giải đáp!</span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>Xem thêm dịch vụ tại đây:&nbsp;<Link to="/services" target="_blank" rel="noopener" style={{textDecoration: 'none solid rgb(0, 123, 255)', color: 'rgb(0, 123, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>Dịch vụ Hoa Vạn Sắc</Link></span></p>
                    <h2 style={{fontSize: '32px', marginBottom: '8px', fontWeight: 500, lineHeight: '38.4px', marginTop: '0px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>__________</span><br style={{boxSizing: 'border-box'}} /> <span style={{fontFamily: 'helvetica, arial, sans-serif', fontSize: '14ptbox-sizing:border-box'}}><strong style={{fontWeight: 700, boxSizing: 'border-box'}}>HỘP SÁNG TẠO HOA VẠN SẮC</strong></span></h2>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>📬&nbsp;hoavansac@gmail.com</span><br style={{boxSizing: 'border-box'}} /> <span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>☎️&nbsp;082 685 2222 – 024 39938436</span></p>
                    <p style={{marginTop: '0px', marginBottom: '16px', boxSizing: 'border-box'}}><span style={{fontFamily: 'helvetica, arial, sans-serifbox-sizing:border-box'}}>📌 Facebook:&nbsp;<a href="https://www.facebook.com/hoavansac.exe101" target="_blank" rel="noopener" style={{textDecoration: 'none solid rgb(0, 123, 255)', color: 'rgb(0, 123, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>Hoa Vạn Sắc </a></span></p>
                </article>
            </div> {/* end col-9 */}
            {/* end col-3 */}
        </div>
    </div>
</div>
        </>
    )
}

export default About;

const Footer = () => {
  return (
    <footer className="text-left" style={{background: 'rgb(14, 127, 133) none repeat scroll 0% 0% / auto padding-box border-box', position: 'relative', marginTop: '20px', display: 'block', boxSizing: 'border-box'}}>
    <div style={{maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box'}}>
        <div style={{display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px', boxSizing: 'border-box'}}>
            <div style={{flex: '0 0 25%', maxWidth: '25%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box'}}>
                <h3 style={{position: 'relative', fontSize: '18px', margin: '30px 0px 15px', color: 'rgb(255, 255, 255)', fontFamily: 'Inter', marginBottom: '15px', fontWeight: 500, lineHeight: '21.6px', marginTop: '30px', boxSizing: 'border-box'}}>Sản phẩm tiêu biểu</h3>
                <ul style={{padding: '0px', margin: '0px', marginTop: '0px', marginBottom: '0px', boxSizing: 'border-box'}}>
                    <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a href="/#/products/1" style={{color: 'rgb(255, 255, 255)', transition: 'all 0.2s ease 0s', textDecoration: 'none solid rgb(255, 255, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>Bộ Kit Đèn Ông Sao</a></li>
                    <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a href="/#/products/2" style={{color: 'rgb(255, 255, 255)', transition: 'all 0.2s ease 0s', textDecoration: 'none solid rgb(255, 255, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>Bộ Kit Hello Kitty</a></li>
                    <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a href="/#/products/3" style={{color: 'rgb(255, 255, 255)', transition: 'all 0.2s ease 0s', textDecoration: 'none solid rgb(255, 255, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>KIT LÀM TRANH HOA HƯỚNG DƯƠNG THỦ CÔNG</a></li>
                    <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a href="/#/products/7" style={{color: 'rgb(255, 255, 255)', transition: 'all 0.2s ease 0s', textDecoration: 'none solid rgb(255, 255, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>Bộ kit ghép tranh vải vụn</a></li>
                </ul>
            </div>
            <div style={{flex: '0 0 25%', maxWidth: '25%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box'}}>
                <h3 style={{position: 'relative', fontSize: '18px', margin: '30px 0px 15px', color: 'rgb(255, 255, 255)', fontFamily: 'Inter', marginBottom: '15px', fontWeight: 500, lineHeight: '21.6px', marginTop: '30px', boxSizing: 'border-box'}}>Navigation</h3>
                <div style={{marginBottom: '20px', boxSizing: 'border-box'}}>
                    <ul style={{padding: '0px', margin: '0px', marginTop: '0px', marginBottom: '0px', boxSizing: 'border-box'}}>
                        <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a className="text-decoration-none text-white" href="/#/">Trang chủ</a></li>
                        <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a className="text-decoration-none text-white" href="/#/about">Giới thiệu</a></li>
                        <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a className="text-decoration-none text-white" href="/#/products">Sản phẩm</a></li>
                        <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a className="text-decoration-none text-white" href="/#/services">Dịch vụ tổ chức Worshop</a></li>
                        <li style={{listStyle: 'outside none none', margin: '10px 0px', color: 'rgb(255, 255, 255)', boxSizing: 'border-box'}}><a className="text-decoration-none text-white" href="/#/contacts">Liên hệ</a></li>
                    </ul>
                </div>
            </div>
            <div style={{flex: '0 0 25%', maxWidth: '25%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box'}}>
                <h3 style={{position: 'relative', fontSize: '18px', margin: '30px 0px 15px', color: 'rgb(255, 255, 255)', fontFamily: 'Inter', marginBottom: '15px', fontWeight: 500, lineHeight: '21.6px', marginTop: '30px', boxSizing: 'border-box'}}>Liên hệ</h3>
                <div style={{marginBottom: '20px', boxSizing: 'border-box'}}>
                    <p style={{margin: '10px 0px', color: 'rgb(255, 255, 255)', fontSize: '13.5px', marginTop: '10px', marginBottom: '10px', boxSizing: 'border-box'}}>Email: <a href="mailto:tadahdiy@gmail.com" style={{color: 'rgb(255, 255, 255)', textDecoration: 'none solid rgb(255, 255, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}>hoavansac@gmail.com</a></p>
                    <p style={{margin: '10px 0px', color: 'rgb(255, 255, 255)', fontSize: '13.5px', marginTop: '10px', marginBottom: '10px', boxSizing: 'border-box'}}>Hotline: 035 382 8835 </p>
                </div>
            </div>
            <div style={{flex: '0 0 25%', maxWidth: '25%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box'}}>
                <h3 style={{position: 'relative', fontSize: '18px', margin: '30px 0px 15px', color: 'rgb(255, 255, 255)', fontFamily: 'Inter', marginBottom: '15px', fontWeight: 500, lineHeight: '21.6px', marginTop: '30px', boxSizing: 'border-box'}}> Mạng xã hội </h3>
                <div style={{boxSizing: 'border-box'}}>
                    <ul style={{padding: '0px', margin: '5px 0px 0px', marginTop: '5px', marginBottom: '0px', boxSizing: 'border-box'}}>
                        <li style={{listStyle: 'outside none none', margin: '10px 10px 10px 0px', color: 'rgb(255, 255, 255)', display: 'inline-block', marginRight: '10px', backgroundColor: 'rgb(0, 152, 119)', borderRadius: '100%', boxSizing: 'border-box'}}> <a href="" style={{color: 'rgb(255, 255, 255)', transition: 'all 0.2s ease 0s', width: '45px', height: '45px', lineHeight: '45px', display: 'inline-block', fontSize: '18px', textAlign: 'center', borderRadius: '50%', textDecoration: 'none solid rgb(255, 255, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}> <i className="fa fa-facebook" style={{display: 'inline-block', fontStyle: 'normal', fontVariant: 'normal', fontKerning: 'auto', fontOpticalSizing: 'auto', fontFeatureSettings: 'normal', fontVariationSettings: 'normal', fontWeight: 400, fontStretch: '100%', lineHeight: '18px', fontFamily: 'FontAwesome', fontSize: '18px', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', boxSizing: 'border-box'}}> </i> </a> </li>
                        <li style={{listStyle: 'outside none none', margin: '10px 10px 10px 0px', color: 'rgb(255, 255, 255)', display: 'inline-block', marginRight: '10px', backgroundColor: 'rgb(0, 152, 119)', borderRadius: '100%', boxSizing: 'border-box'}}> <a href="" style={{color: 'rgb(255, 255, 255)', transition: 'all 0.2s ease 0s', width: '45px', height: '45px', lineHeight: '45px', display: 'inline-block', fontSize: '18px', textAlign: 'center', borderRadius: '50%', textDecoration: 'none solid rgb(255, 255, 255)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}> <i className="fa fa-envelope" style={{display: 'inline-block', fontStyle: 'normal', fontVariant: 'normal', fontKerning: 'auto', fontOpticalSizing: 'auto', fontFeatureSettings: 'normal', fontVariationSettings: 'normal', fontWeight: 400, fontStretch: '100%', lineHeight: '18px', fontFamily: 'FontAwesome', fontSize: '18px', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', boxSizing: 'border-box'}}> </i> </a> </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
  );
};

export default Footer;

const InfoHeader = () => {
    return (<div style={{backgroundColor: 'rgb(243, 245, 245)', boxSizing: 'border-box'}}>
        <div style={{maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box'}}>
            <div style={{display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px', boxSizing: 'border-box'}}>
                <div style={{flex: '0 0 50%', maxWidth: '50%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box'}}>
                    <ul style={{padding: '0px', margin: '0px', marginTop: '0px', marginBottom: '0px', boxSizing: 'border-box'}}>
                        <li style={{listStyle: 'outside none none', display: 'inline-block', padding: '8px 12px', borderLeft: '0.8px solid rgb(223, 228, 228)', boxSizing: 'border-box'}}> <a href="https://www.facebook.com/hoavansac.exe101" style={{color: 'rgb(0, 0, 0)', textDecoration: 'none solid rgb(0, 0, 0)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}> <i className="fa fa-facebook" style={{display: 'inline-block', fontStyle: 'normal', fontVariant: 'normal', fontKerning: 'auto', fontOpticalSizing: 'auto', fontFeatureSettings: 'normal', fontVariationSettings: 'normal', fontWeight: 400, fontStretch: '100%', lineHeight: '15px', fontFamily: 'FontAwesome', fontSize: '15px', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', boxSizing: 'border-box'}}> </i> </a> </li>
                        <li style={{display: 'inline-block', listStyle: 'outside none none', padding: '8px 12px', borderLeft: '0.8px solid rgb(223, 228, 228)', boxSizing: 'border-box'}}> <a href="mailto:hoavansac@gmail.com" style={{color: 'rgb(0, 0, 0)', textDecoration: 'none solid rgb(0, 0, 0)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box'}}> <i className="fa fa-envelope" style={{display: 'inline-block', fontStyle: 'normal', fontVariant: 'normal', fontKerning: 'auto', fontOpticalSizing: 'auto', fontFeatureSettings: 'normal', fontVariationSettings: 'normal', fontWeight: 400, fontStretch: '100%', lineHeight: '15px', fontFamily: 'FontAwesome', fontSize: '15px', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', boxSizing: 'border-box'}}> </i> hoavansac@gmail.com </a> </li>
                    </ul>
                </div>
                <div style={{display: 'block', flex: '0 0 50%', maxWidth: '50%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box'}}>
                    <p style={{margin: '0px', padding: '8px 12px', textAlign: 'right', float: 'right', marginTop: '0px', marginBottom: '0px', boxSizing: 'border-box'}}> <i style={{display: 'inline-block', fontStyle: 'normal', fontVariant: 'normal', fontKerning: 'auto', fontOpticalSizing: 'auto', fontFeatureSettings: 'normal', fontVariationSettings: 'normal', fontWeight: 400, fontStretch: '100%', lineHeight: '15px', fontFamily: 'FontAwesome', fontSize: '15px', textRendering: 'auto', WebkitFontSmoothing: 'antialiased', boxSizing: 'border-box'}}> </i> SDT: 035 382 8835 </p>
                </div>
            </div>
        </div>
    </div>)
}

export default InfoHeader;